<div class="container">
    <div class="row" style="margin-top: 2rem;">
        <div class="col-md-12" style="text-align: center;">
            <p> {{article && article.attributes.published}}</p>
            <h2>{{article && article.attributes.title}}</h2>
        </div>
        <!-- <div class="col-md-12" *ngIf="article && article.attributes.cover.data"
            style="justify-content: center;align-items: center;display: flex;margin-top: 1rem;margin-bottom: 1.5rem;">
            <img class="img-fluid" [alt]="article.attributes.cover.data.attributes.alternativeText" [src]="cover" style="object-fit: cover;; ">
        </div> -->
        <div class="col-md-2"></div>
        <div class="col-md-8" [innerHTML]="body" style="margin-top: 1rem;"></div>
        <div class="col-md-2"></div>
        <div *ngIf="article && article.attributes.files.data" style="margin-top: 3vh;">
            <p>Αρχεία:</p>
            <div *ngFor="let item of article.attributes.files.data">
                <a [href]="item.attributes.url">{{item.attributes.name}}</a>
            </div>
        </div>

        <div *ngIf="article && article.attributes.photos.data" style="margin-top: 3vh;">
            <p>Φωτογραφίες:</p>
            <p-galleria [value]="article.attributes.photos.data" [(visible)]="displayCustom"
                [(activeIndex)]="activeIndex" [responsiveOptions]="responsiveOptions"
                [containerStyle]="{'max-width': '850px'}" [numVisible]="7" [circular]="true" [fullScreen]="true"
                [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000">
                <ng-template pTemplate="item" let-item>
                    <img [src]="item.attributes.url" style="width: 100%; display: block;" />
                </ng-template>
                <ng-template pTemplate="thumbnail" let-item>
                    <div class="grid grid-nogutter justify-content-center">
                        <img [src]="item.attributes.url" style="display: block;" class="imag-fluid" />
                    </div>
                </ng-template>
            </p-galleria>
            <div *ngIf="article.attributes.photos.data" style="display: flex;flex-direction: row;">
                <div *ngFor="let image of article.attributes.photos.data; let index = index;" class="col-2"
                    key="index">
                    <img [src]="image.attributes.url" [alt]="image.attributes.name"
                        style="cursor: pointer;width: 10rem;" class="img-fluid" (click)="imageClick(index)" />
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 2rem;background: #F9FAFB;padding: 3rem;" *ngIf="moreArticles.length >0">

        <div class="col-md-12">
            <h3>Περισσότερα άρθρα</h3>
        </div>
        <div class="col-12 col-sm-12 p-0 m-0">
            <div class="container-fluid">
                <div class="row">
                    <a class="col-md-6 news " routerLink="/page/{{item.id}}" *ngFor="let item of moreArticles">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" [src]="item.cover"></div>
                                <div class="col-md-6">
                                    <label>{{item.attributes.published}}</label>
                                    <p class="title">{{item.attributes.title}}</p>
                                    <!-- <p>Από 1ης Ιανουαρίου 2022, επιβάλλεται ως περιβαλλοντικό τέλος 0,04 λεπτά ανά τεμάχιο σε πάσης φύσεως πλαστικές...</p> -->
                                </div>
                            </div>
                        </div>
                    </a>
                    <!-- <a class="col-md-6 news" routerLink="/page/1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" src="/assets/Image(1).png"></div>
                                <div class="col-md-6">
                                    <label>Ανακοινώσεις</label>
                                    <p class="title">Τέλος στη χρήση των πλαστικών με τον Νόμο 4736/2020</p>
                                    <p>Από 1ης Ιανουαρίου 2022, επιβάλλεται ως περιβαλλοντικό τέλος 0,04 λεπτά ανά
                                        τεμάχιο σε πάσης φύσεως πλαστικές...</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="col-md-6 news" routerLink="/page/1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" src="/assets/Image(2).png"></div>
                                <div class="col-md-6">
                                    <label>Ανακοινώσεις</label>
                                    <p class="title">Χρηματοδότηση από το επόμενο ΕΣΠΑ του Μηχανισμού Διάγνωσης Αναγκών
                                        της αγοράς εργασίας της ΠΚΜ</p>
                                    <p>Τη διασφάλιση της χρηματοδότησης του Μηχανισμού Διάγνωσης...</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="col-md-6 news" routerLink="/page/1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" src="/assets/Image(3).png"></div>
                                <div class="col-md-6">
                                    <label>Ανακοινώσεις</label>
                                    <p class="title">Ηλεκτρονικό Ράφι Online Πωλήσεων στην Τοπική Ηλεκτρονική Αγορά του
                                        Νομού Φλώρινας και στην Πανελλήνια Ηλεκτρονική Αγορά www.directmarket.gr</p>
                                    <p>Αποκτήστε Ηλεκτρονικό Ράφι Online Πωλήσεων...</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="col-md-6 news" routerLink="/page/1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" src="/assets/Image(3).png"></div>
                                <div class="col-md-6">
                                    <label>Ανακοινώσεις</label>
                                    <p class="title">Τέλος στη χρήση των πλαστικών με τον Νόμο 4736/2020</p>
                                    <p>Από 1ης Ιανουαρίου 2022, επιβάλλεται ως περιβαλλοντικό τέλος 0,04 λεπτά ανά
                                        τεμάχιο σε πάσης φύσεως πλαστικές...</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a class="col-md-6 news" routerLink="/page/1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6"><img class="img-fluid" src="/assets/Image(2).png"></div>
                                <div class="col-md-6">
                                    <label>Ανακοινώσεις</label>
                                    <p class="title">17η Διεθνής Έκθεση «ΑGRICULTURE AND LIVESTOCK EXHIBITION» στη
                                        Σμύρνη στις 1-4 Φεβρουαρίου 2022</p>
                                    <p>Κατά τις ημερομηνίες 2-6 Φεβρουαρίου 2022 πρόκειται να πραγματοποιηθεί η ...</p>
                                </div>
                            </div>
                        </div>
                    </a> -->
                </div>
            </div>
        </div>
    </div>

</div>